import React, { useEffect, useState } from 'react';
import InviteBillingCustomer from './invite/inviteBilling';
import { useAuth } from '../../context/authContext';
import { useApi } from '../../context/ApiCalls';
import Modal from '../../components/modal/modal';
import Table from './table/table';
import { Button } from '@mui/material';
import NewCustomer from './newCustomer/newCustomer';
import ServerTable from './table/serverTable';
import NewServer from './servers/newServer';
import UpdateLicense from './servers/updateLicense';

const AdminPage = () => {
  const { user } = useAuth();

  const [clients, setClients] = React.useState<[]>([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [content, setContent] = React.useState('');
  const [title, setTitle] = React.useState('');
  const [update, setUpdate] = React.useState(0);
  const [invintationCustomer, setInvintationCustomer] = useState<any>(null);
  const [licenseUpdateServer, setLicenseUpdateServer] = useState<any>(null);
  const [servers, setServers] = React.useState<[]>([]);
  const { billingCustomer, getServers, updateServerLicense, newServer } = useApi();
  useEffect(() => {
    let isMounted = true;
    async function fetchBillingCustomer() {
      if (!user || user.role !== 'ADMIN') return;
      const clients = await billingCustomer();
      if (isMounted) setClients(clients);
    }
    fetchBillingCustomer();
    return () => {
      isMounted = false;
    };
  }, [user, billingCustomer, update]);

  useEffect(() => {
    async function fetchServers() {
      const servers = await getServers();
      console.log(servers);
      if (servers) setServers(servers);
    }
    fetchServers();
    // eslint-disable-next-line
  }, []);
  if (!clients) {
    return <div className="flex w-full h-full items-center justify-center">Loading...</div>;
  }
  const updateLicenseApi = async (serverId: number, newLicenseCount: number) => {
    const response = await updateServerLicense({ serverId, newLicenseCount });
    if (response && response.ok) {
      const servers = await getServers();
      if (servers) setServers(servers);
      setOpenModal(false);
    } else {
      console.error('Failed to update license');
    }
  };

  const handleLicenseUpdate = (server: any) => {
    setLicenseUpdateServer(server);
    setContent('updateLicense');
    setOpenModal(true);
    setTitle('Päivitä lisenssi');
  };

  const handleAddNewServer = async (server: any) => {
    const newServerResponse = await newServer(server);
    if (newServerResponse?.ok) {
      const servers = await getServers();
      if (servers) setServers(servers);
      setContent('');
      setOpenModal(false);
    } else {
      console.error('Failed to add new server');
    }
  };

  const handleInviteReseller = (client: any) => {
    setInvintationCustomer(client);
    setTitle('Kutsu käyttäjä yritykselle');
    setContent('invite');
    setOpenModal(true);
  };

  const handleResellerInvintation = async (invintation: any) => {
    setContent('');
    setInvintationCustomer(null);
    setOpenModal(false);
  };
  const handleAddNewCustomer = () => {
    setUpdate(update + 1);
    setContent('');
    setOpenModal(false);
  };

  const modalContentMap = {
    invite: <InviteBillingCustomer invintationCustomer={invintationCustomer} handleResellerInvintation={handleResellerInvintation} />,
    newCustomer: <NewCustomer handleAddNewCustomer={handleAddNewCustomer} />,
    newServer: <NewServer handleAddNewServer={handleAddNewServer} />,
    updateLicense: <UpdateLicense licenseUpdateServer={licenseUpdateServer} updateLicenseApi={updateLicenseApi} />,
  };

  const handleModalContent = () => {
    return modalContentMap[content as keyof typeof modalContentMap] || null;
  };

  const ModalButton = ({ header, content, title, setOpenModal, text }: { header?: string; content: string; title: any; setOpenModal: any; text: string }) => {
    return (
      <div className="w-full flex justify-between items-start pt-12 pb-4 px-4">
        <h2 className="text-xl font-semibold">{header}</h2>
        <div className="hover:scale-105 hover:animate-pulse ease-in-out duration-300">
          <Button
            sx={{
              borderRadius: 6,
            }}
            variant="contained"
            onClick={() => {
              setContent(content);
              setTitle(title);
              setOpenModal(true);
            }}
          >
            {text}
          </Button>
        </div>
      </div>
    );
  };
  return (
    <div className="w-full md:p-8">
      <Modal
        title={title}
        show={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
      >
        {handleModalContent()}
      </Modal>

      <ModalButton header="Maahantuojat" content="newCustomer" title="Uusi asiakas" setOpenModal={setOpenModal} text="Uusi asiakas +" />

      <Table clients={clients} handleInviteReseller={handleInviteReseller} />

      <ModalButton header="Palvelimet" content="newServer" title="Uusi palvelin" setOpenModal={setOpenModal} text="Uusi serveri +" />

      {/* <Servers /> */}
      <ServerTable servers={servers} handleLicenseUpdate={handleLicenseUpdate} />
    </div>
  );
};

export default AdminPage;
